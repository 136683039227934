<template>
    <div class="rounded-lg pb-5 relative">
        <LoadingComponent :isLoading="loading" />
        <div class="shadow-lg rounded-lg bg-white flex items-center p-5 mb-3">
            <div class="grid grid-cols-12 gap-4">
                <div class="col-span-3">
                    <label class="text-gray-400">
                        手機
                        <div class="flex">
                            <div class="phone-select">
                                <el-select v-model="filterOpts.phone_country" size="small" placeholder="请选择">
                                    <el-option v-for="item in phoneOptions" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                                </el-select>
                            </div>
                            <div>
                                <el-input v-model="filterOpts.phone" size="small" clearable> </el-input>
                            </div>
                        </div>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        身分證 / 統編
                        <el-input v-model="filterOpts.government_id" size="small" clearable> </el-input>
                    </label>
                </div>
                <div class="col-span-3">
                    <label class="text-gray-400">
                        狀態
                        <el-select v-model="filterOpts.status" size="small" class="w-full">
                            <el-option v-for="(event, index) in statusOptions" :key="index" :label="event.label" :value="event.value">
                                {{ event.label }}
                            </el-option>
                        </el-select>
                    </label>
                </div>
                <div class="col-span-3 flex items-end">
                    <btn
                        v-permission="['create']"
                        class="mr-3"
                        size="md:text-sm text-xs px-5 py-1 rounded min-w-[150px] max-w-[250px]"
                        color="border border-red-500 text-orange-600"
                        @onClick="goCreate">
                        <i class="el-icon-plus text-lg"></i>
                        新增介紹人
                    </btn>
                    <button class="orange-btn-800 md:text-sm text-xs mr-1 w-[73px] h-[40px] flex-1" @click="goSearch">搜尋</button>
                    <button class="black-btn-outline md:text-sm text-xs w-[73px] h-[40px] flex-1" @click="reset">重置</button>
                </div>
            </div>
        </div>
        <div class="overflow-x-auto bg-white">
            <div>
                <table class="myTable">
                    <thead>
                        <tr>
                            <th>介紹人 / 公司名稱</th>
                            <th>真實姓名 / 代表人</th>
                            <th>手機</th>
                            <th>身分證 / 統編</th>
                            <th>帳號</th>
                            <th v-permission="['update']">編輯</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in datas" :key="item.id" class="text-center duration-200 transition">
                            <td>{{ item.name }}</td>
                            <td>{{ item.representative }}</td>
                            <td>{{ item.phone }}</td>
                            <td>{{ item.government_id }}</td>
                            <td>{{ item.status === 0 ? "使用中" : "停用中" }}</td>
                            <td v-permission="['update']" class="cursor-pointer" @click="editCompany(item.id)">
                                <i class="far fa-edit"></i>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div class="justify-center mt-3 flex mb-3">
                    <MyPagination
                        :paginationData="paginationData"
                        :currentPage="currentPage"
                        @onPageSizeChange="pageSizeChange"
                        @onCurrentPageChange="currentPageChange" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// 分頁組件
import MyPagination from "@/components/MyPagination.vue";
// 旋轉動畫組件
import LoadingComponent from "@/components/Loading.vue";
// options phone
import { phoneOptions } from "@/config/optionsConfig.js";
// 新增介紹人彈窗
import Btn from "@/components/Button.vue";

// init search Filter options
const defaultFilterOpts = {
    government_id: "",
    phone_country: "886",
    phone: "",
    status: null, // 0(使用中), 1(停用）
};
export default {
    name: "WithdrawRecord",
    components: {
        LoadingComponent,
        MyPagination,
        Btn,
    },
    computed: {
        // 統整 getlist api filters
        filterData() {
            const phone = this.filterOpts.phone ? `${this.filterOpts.phone_country}${this.filterOpts.phone}` : "";
            const request = {
                ...this.filterOpts,
                phone: phone,
                limit: this.paginationData.limit,
                page: this.currentPage,
            };

            if (!request.government_id) {
                delete request.government_id;
            }

            if (!request.phone) {
                delete request.phone;
            }

            return request;
        },
        // 帳號狀態 options
        statusOptions() {
            return [
                {
                    label: "不限",
                    value: null,
                },
                {
                    label: "使用中",
                    value: "0",
                },
                {
                    label: "停用中",
                    value: "-1",
                },
            ];
        },
        // 手機國碼
        phoneOptions() {
            return phoneOptions;
        },
    },
    data() {
        return {
            loading: false,
            datas: [],
            datasTotal: [],
            paginationData: {
                limit: 10,
                total: 0,
            },
            currentPage: 0,
            filterOpts: { ...defaultFilterOpts },
        };
    },
    methods: {
        async getList() {
            this.loading = true;
            const { status, data } = await this.$api
                .GetBrokersListApi(this.filterData)
                .then((res) => {
                    return { data: res.data, status: res.status };
                })
                .catch(() => {
                    this.$message({
                        type: "error",
                        message: "取得資料失敗",
                    });
                })
                .finally(() => {
                    this.loading = false;
                });
            if (status === 200) {
                this.datas = [...data.data];
                this.paginationData = {
                    limit: data.per_page,
                    total: data.total,
                };
            }
        },

        goSearch() {
            this.currentPage = 1;
            this.getList();
        },
        reset() {
            this.filterOpts = { ...defaultFilterOpts };
            this.currentPage = 1;
            this.paginationData = {
                limit: 10,
                total: 0,
            };
            this.getList();
        },
        pageSizeChange(val) {
            // 更新每頁取得幾筆資料參數
            this.paginationData.limit = val;
            // 當前頁面設定為第一頁
            this.currentPage = 1;
            this.getList();
        },
        currentPageChange(val) {
            // 設定目前分頁為第幾面
            this.currentPage = val;
            this.getList();
        },
        // 連結相關
        editCompany(id) {
            this.$router.push({ path: `/user_list/edit_introducer/${id}/profile` });
        },
        goCreate() {
            this.$router.push("/user_list/add_introducer");
        },
    },
    watch: {
        "filterOpts.phone"(val) {
            if (this.$isEmpty(val)) {
                return;
            }
            if (val[0] == 0 && val[1] == 9) {
                this.filterOpts.phone = val.substring(1);
                return;
            }
        },
    },
    mounted() {
        this.getList();
    },
    activated() {
        this.getList();
    },
};
</script>

<style lang="scss" scoped>
label {
    color: #757575;
    font-size: 16px;
    font-weight: 400;
    line-height: 34px;
}
.show-limit {
    width: 100%;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #757575;
    font-weight: 500;
}
.phone-select {
    width: 150px;
    margin-right: 5px;
}
.total-board {
    width: 100%;
    margin-bottom: 10px;
    display: flex;
    .left {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 50px;
        margin-right: 20px;
        background: white;
    }
    .right {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 50px;
        background: white;
    }
}

::v-deep .el-input__inner {
    max-width: 100%;
}

::v-deep .el-range-separator {
    width: 20px;
}
</style>
